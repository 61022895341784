import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       
    </Carousel>
  );
}

export default PowerCarousel;