import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | Safe Solar Uganda";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To diligently execute engineering projects with passion, integrity and innovation.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To deliver outstanding project management and engineering services while ensuring high value for our clients.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    {/* <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                            <li>Customer Satisfaction.</li>
                            <li>Communication.</li>
                            <li>Ethical Practices & Honesty.</li>
                            <li>Quality Products & Safe Environment.</li>
                            <li>Appreciation & Recognition of our People.</li>
                            <li>Profitable & Growing Business.</li>
                          </ul>
                          
                          

                    </article> */}

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div>
          </div>
    </section>
    

    </>
  )
}

export default WhoWeAre