import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaQuoteLeft} from 'react-icons/fa';
import {MdHealthAndSafety} from 'react-icons/md';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Hotel Zippy | Style Accompanied by Comfort";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover bg-cover-a'>
      <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
          <div className='circle3'>
          </div>
        <div className='content-container'>
           
          {/* <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div> */}

                 <p className='home-p'> 
                 <strong>Hotel Zippy</strong> is located in Mbale city, Eastern part of Uganda in the heart of a cool and serene environment, providing a perfect getaway for guests seeking a calm and relaxing atmosphere. The hotel is nestled amidst beautiful scenary, offering a peaceful retreat from the hustle and bustle of everday life.  
                </p>
<p className='home-p'>
  A modern and stylish design, with luxurious amenities that ensure a comfortable and memorable stay. Each room is elegantly furnished with plush bedding, comfortable seating, and state-of-the-art technology to enhance the guest experience.
</p>

<p className='home-p'>
  <strong>Hotel Zippy</strong> also provides a healthy breakfast, but other meals can also be provided on order. We have a well-equipped kitchen for those who may wish to cook for themselves.
</p>


 
        </div>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(109, 65, 36, 0.81), rgba(109, 65, 36, 0.81)), url("./images/11.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    textAlign:'center'
    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Commitment</h2>
            <div className='pledge-line'></div>
         <p> 
          <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 Hotel Zippy is committed to providing outstanding customer service, ensuring that every guest feels welcome and valued from the moment they arrive. The hotel's warm and friendly staff are always on hand to assist with any queries or requests, ensuring that your stay is nothing short of perfect.
</p>
        </div>
        <Link to='/contact' className='booking-link' onClick={scrollFunc}>Book Now</Link>
    </section>
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Our Policies</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Quality Policy</h3>
          <p>We are committed to providing quality service in performing our duties in a professional manner with integrity and abiding by professional and legal requirements at all times.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><MdHealthAndSafety></MdHealthAndSafety></span>
          </div>
          <h3 className='home-service-title'>Health & Safety Policy</h3>
          <p>We ensure the Health and Safety of our clients, employees and communities within which we work, using structured systems, processes and procedures.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaLeaf></FaLeaf></span>
          </div>
          <h3 className='home-service-title'>Environmental Policy</h3>
          <p>We strive to maintain and improve the level of environmental management by strictly complying with environment-related laws, ordinances and agreements in all aspects of our activities.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section>
   
     */}
  
    </>
  )
}

export default Home