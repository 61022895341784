import React, {useState, useEffect} from 'react';
import {FaChevronDown,FaChevronUp} from 'react-icons/fa';
import ValuesData from './AccordionData';

const CoreValues = () => {
  let [selected, setSelected] = useState(0);
    
    
let AccordionFunc = (index)=>{

  if (selected === index){
  return setSelected(null);
}
  setSelected(index);
}


 


  return (
    <div className='core-values-container1'>
         {ValuesData.map((item, index)=>{
                       

            return <article className='core-value' key={index}>
              
            <h3  onClick={()=>AccordionFunc(index)}>{item.title}   <div className='core-value-icons'><span>{`${selected === index? '-': '+'}`} </span></div></h3>
            <p className={`${selected === index? '': 'hide'}`} > {item.content}</p>
         </article>



         })

         }



         {/* <article className='core-value' >
            
            <h3 > Customer Satisfaction      <div className='core-value-icons'><span > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span> </div></h3>
             
             <p >We value and put our customers' needs above everything else.</p>
         </article>

         <article className='core-value'>
            <h3 >Integrity     <div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span> </div></h3>
            <p >  We serve our customers with diligence, transparency, accountability and utmost honesty.</p>
         </article>

           <article className='core-value'>
            <h3 >Devotion to Excellence   <div className='core-value-icons'><span > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span></div></h3>
           <p > Through study and research, we daily strive to offer the best services to our customers. We believe that today is better than yesterday and tomorrow ought to be better than today.  </p>
         </article>

          <article className='core-value'>
            <h3 >Care<div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span> </div></h3>
            <p >  We cherish and honour the contibution of every employee of the organization, knowing that good employees are the conerstone of our success.</p>
         </article>

         <article className='core-value'>
            <h3 >Equity<div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span> </div></h3>
           <p > We preach and foster fairness for all our employees and stakeholders by providing fair and equitable renumeration and treating all stakeholders in a deserving manner.</p>
         </article>

         <article className='core-value'>
            <h3 >Passion<div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span></div></h3>
           <p >  We are passionate about customers' needs and satisfaction. Hence, we build long-term customer relationships.</p>
         </article>

          <article className='core-value'>
            <h3 >Teamwork<div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span></div></h3>
          <p > We encourage and promote the spirit of teamwork, trust and partnerships. 'One for all, all for one.' </p> 
         </article>

         <article className='core-value'>
            <h3>Professionalism<div className='core-value-icons'><span  > <FaChevronUp></FaChevronUp></span>   <span > <FaChevronDown></FaChevronDown></span> </div></h3>
         <p >We strive for excellence in all our endeavors.</p> 
         </article> */}



    </div>
  )
}

export default CoreValues;