import React, {useEffect} from 'react'
import Bedding from '../components/BuildingsCarousel';
import Gardens from '../components/PowerCarousel';
import Facilities from '../components/RoadCarousel';
import {Link} from 'react-router-dom';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}
const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | Hotel Zippy';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
        <div className='line1 line1-a'></div>
      <div className='content-container gallery'>
        <article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
           <Facilities></Facilities>
           <p>Our Facilities</p>
         </article>
        <article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
           <Bedding></Bedding>
           <p>Our Beds</p>
         </article>
         <article className='gallery-content'>
          {/* <img src='./images/u1.jpg' className='gallery-image' alt='image'></img> */}
           <Gardens></Gardens>
           <p>Our Gardens</p>
         </article>
         
      </div>
 <Link to='/contact' className='booking-link booking-link1 booking-link2' onClick={scrollFunc}>Book Now</Link>
    </section>
  )
}

export default Gallery