import React from 'react'

let ValuesData = [

{title: "Integrity",      
content: "We carry out our business activities in an ethical manner and in strict compliance with the local and international laws wherever we operate."
},

{title: "Responsibility",      
content: "We are committed to our employees, stakeholders and our clients with prime focus to their growth and providing them safe and healthy environment."
},
{title: "Valuing People",      
content: "We invest in individuals working for us. We empower them to reach their full potential so they can have the freedom to take their own decisions."
},
{title: "Flexibility",      
content: "We have a flexible and practical approach to clients’ requirements in delivering high-value work on time and within budget."
},
{title: "Delivery",      
content: "Every day we focus on our employees, stakeholders and clients and deliver our promises. We work hard to build long-term partnerships with clients for mutual benefit."
}
];

export default ValuesData;