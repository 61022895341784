import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />

      </Carousel.Item>
  
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/10.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
    </Carousel>
  );
}

export default TelecomCarousel;