import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className='home-carousel'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Hotel Zippy</h3>
          <p className="slide-p slide-p1">"Style Accompanied by Comfort"</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
          Our rooms are elegantly furnished with plush beddings
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/2.jpg"
           src="./images/10.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2">Providing a healthy breakfast</p>
        </Carousel.Caption>
      </Carousel.Item>
      

      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
           We have comfortable seats
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           Come enjoy a cool & serene environment
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFade;