import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Projects | The Eight Investments Company Limited';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Projects</h2>

         

           <table className='team-table'>
    
    <tr>
      <th>S/No.</th>
      <th>Organization</th>
      <th>Project</th>
      <th className='date-col'>Date</th>
      <th>Brief</th>
      <th>Role to be played by The Eight Investments Company Limited </th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Food and Agriculture Organization</td>
      <td>Food Loss Analysis: Causes and Solutions: Case Study of the
Small-scale Agriculture and Fisheries subsectors in Uganda</td>
      <td>Dec. 2016 - Feb. 2017</td>
      <td> The food loss analysis study on selected food supply chains is aimed at
the identification and quantification of the main causes of food loss;
analysis of the impact and solutions to reduce food losses as a way of
ensuring food security and sustainability in feeding the population
amidst the present dangers of climate change in Uganda and the
world at large. </td>
      <td> Develop of a proposal on a research experiment to determine the
impact of food loss intervention. The assignment data analysis to set up
the study groups and the design of data collection methodologies
including data instruments. The Eight Investment Consults will also conduct a pre-testing of the data collection instruments and conduct
preliminary analysis to test the external validity assumptions. </td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Northern Uganda Social Action Fund (NUSAF) II and
Action Against Hunger (USA)</td>
<td>Impact Evaluation of Improving Food Security and Enhancing
Resilience in Karamoja </td>
<td>Feb. 2016</td>
<td>This evaluation focused on understanding the impact of interventions
that were implemented in northern Uganda as part of the
re-settlement of the internally displaced persons after the armed
rebellion. This study specifically looked establishing the mechanisms
through which the availability of ready food and cropping land were
contributing factors in long-term settlements among the formerly
internally displaced persons.</td>
<td>The Eight Invesmtent Company Limited was assigned the role of data
collection from the selected participants of the study. Prior to the
collection of the data, the company was involved in the pilot testing of
the data collection instruments. The Eight Investment Company Limited
was also involved in the data entry and analysis stages of the study.</td>

    </tr>
    <tr>
      <td>3.</td>
      <td>National Water and Sewage Corporation (NWSC)</td>
      <td>Project Management Trainer</td>
      <td>Oct. 2013</td>
      <td></td>
      <td>In its bid to improve employee productivity, the NWSC trained among
its staff skills in project management. Our assignment included covering the different tools used in project management. This training took
on both hands on and class work sessions to ensure learning took
place. </td>
    </tr>



  </table>
</div>
           
    </section>
  )
}

export default Team